.particles-container {
    background-color: #e9ddc1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
}

.logo-test {
    background-color: black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
}
.wave {
    text-shadow:
    -1px -1px 0 #e1d9c7,  
     1px -1px 0 #e1d9c7,
    -1px  1px 0 #e1d9c7,
     1px  1px 0 #e1d9c7; 
}
h3 {
  all: unset;
  color: #fff;
  font-size: 8em;
}
.logo-test .wave {
  color: #fff;
  font-size: 8em;
  position: absolute;
  transform: translate(-50%, -50%);
}
.logo-test .wave:nth-child(1) {
    color: transparent;
    -webkit-text-stroke:  #03a9f4;
  }
  
  .logo-test .wave:nth-child(2) {
    color: #8d8a80;
    animation: animate 6s ease-in-out infinite;
  }
  @keyframes animate {
    0%,
    100% {
      clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }
  
    50% {
      clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
  }
  .waviy {
    position: relative;
  }
  .waviy span {
    position: relative;
    display: inline-block;
    font-size: 8em;
    color: #e1d9c7;
    animation: flip 2s infinite;
    animation-delay: calc(.2s * var(--i))
  }
  @keyframes flip {
    0%,80% {
      transform: rotateY(360deg) 
    }
  }