.logo-flavor-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* Add other styles as needed */
    background: transparent;
    z-index: 6;
    position: absolute;
    width: 60%;
    transition: transform 2s ease;
  }

.logo-flavor-container.home {
  transform: translateX(0vh) translateY(0vh);
  width: 900px;

}
.logo-flavor-container.semis {
  width: 900px;
  transform: translateX(0vh) translateY(-23vh);
}
@media (max-width: 1200px) {
  .logo-flavor-container.home {
    width: 60%;
  }
  .logo-flavor-container.semis {
    width: 60%;
    transform: translateX(0vh) translateY(-30vw);
  }
}
@media (max-width: 800px) {
  .logo-flavor-container.home {
    width: 80%;
    transform: translateX(0vh) translateY(-10vw);
  }
  .logo-flavor-container.semis {
    width: 80%;
    transform: translateX(0vh) translateY(-60vw);
  }
}
.logo-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.img-container{
    width: 60px;
    height: 60px;
    transition: top 2s ease;
    background: transparent;
    z-index: 6;
    pointer-events: auto;
}


.img-container img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    object-fit: contain; /* Keep aspect ratio of image */
  }
  
.flavor-text {
    margin-left: 20px; /* Adjust margin as needed */
    /* Add other styles for text */
  }
 
  /* Layout variations */

.tab-header {
    font-size: 36px;
    font-weight: bold;
    /*color: #536878; */
    color: black;
   
}

.tab-header-p {
    font-size: 36px;
    font-weight: bold;
    color: black;
    transition: opacity 0.25s ease;
}

.logo-link-wrap {
  display: inline-block;
}