.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0;
    list-style-type: none;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3b3b3b;
    z-index: 10000;
    
  }
  
  .navbar li {
    color: #8d8a80;
    margin: 0 10px;
    font-size: 14px;
  }

  
  .navbar a {
    text-decoration: none;
    color: #8d8a80;
  }
  
  .navbar a:hover {
    color: #e1d9c7;
  }

  .navbar a.active {
    font-weight: bold;
    color: #e1d9c7;
  }