
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #e4ddcc; /* Match this color to the background of your image */
  display: flex;
  flex-direction: column;
}


.app-container {
  background-color: #e4ddcc;
    background-image: url('./assets/Untitled-6.svg'); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: inset(1px 0); /* Exclude the top and bottom 10px */
    margin: 0;
    padding: 0;
    flex: 1;
    overflow: auto;
}

.background {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-wrapper {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
}

.seifdune-logo {
  width: 100%;
  height: 100%;
  z-index: 2;
}
.visible {
  width: 10%; /* Width of each bar */
}