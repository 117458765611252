
@keyframes moveBackground {
    0% { background-position: 0 0; }
    100% { background-position: 100% 0; }
  }
  @keyframes moveRight {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(100vw - 100px)); } /* Adjust 100px to image width */
  }
  
  @keyframes moveLeft {
    0% { transform: translateX(calc(100vw - 100px)); } /* Adjust 100px to image width */
    100% { transform: translateX(0); }
  }

.home-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-splash {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.home-text {
   text-align: center;
   justify-content: center;
   width: 45%;
   
}



.dune-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 45%;
}
.dune-container svg{

    width: 100%;
    height: 100%;
}

.foonote {
    position: fixed;
    bottom: 0;
}

