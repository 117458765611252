.footer {
    width: 100%;
    color: black; /* Text color */
    padding: 10px 20px; /* Adjust padding as needed */
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  }
  
.left-text,
  .right-text {
    text-decoration: none;
    color: inherit;
  }
  @media (max-width: 800px) {
    .footer {
      font-size: 13px;
      background-color: #e4ddcc;
    }
  }
  @media (max-width: 420px) {
    .footer {
      font-size: 12px;
    }
  }