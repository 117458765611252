
h1 {
    color: black;
    text-decoration: none;
}
h2 {
    width: 50%;
    margin: 0;
    text-decoration: none;
}

.particles-container {
  background-image: url('./assets/Untitled-6.svg');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%; 
}

.card-container-p {
    display: grid;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    width: 60%;
    overflow: hidden; /* Hide overflow content */
    opacity: 0;
    transition: opacity 2s ease 0.5s;
}
.card-container-p.visible {
  opacity: 1;
}

 /* current fade in effect
 
 .card-container-p::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e9ddc1;
  transition: opacity 2s ease;
  opacity: 1;
  z-index: 5; 
  pointer-events: none;
}
.card-container-p.visible::before {
  opacity: 0;
}
*/

.card-p {
    /* background: #8f846e; */
    background: transparent;
    border-radius: 2px;
    width: 200px;
    aspect-ratio: 5 / 3;
    position: relative;
    transition: background 0.1s, color 0.5s ease-in;
    padding: 10px;
    color: #e1d9c7;
    cursor: pointer;
    overflow: hidden;
}
.card-p svg {
    fill: black;
    transition: fill 0.5s ease-in, stroke 0.5s ease-in;
}
.card-p:hover {
    color: black;
    --active: 1;
}

.card-p:hover svg {
    fill: black;
}

.card-p:hover .akeanalogo {
    fill: black;
}
.card-p:hover .akeana-red {
    fill: black;
    stroke: red;
}
.card-p:hover .retymlogo {
  fill: black;
}

.card-p:hover .retym-red {
  fill: red;
}

.card-p:hover .alif-logo {
  fill: black;
}
.card-p:hover .alif-blue {
  fill: #17a1db;
}

.card-p:hover .alif-teal {
  fill: #5dcbed;
}
.card-p:after {
    content: "";
    position: absolute;
    inset: 0;
    background:
      radial-gradient(circle at calc(var(--x) * 1px) calc(var(--y) * 1px), hsl(0 0% 100% / 0.15), transparent 15vmin);
    background-attachment: fixed;
    opacity: var(--active, 0);
    transition: opacity 0.2s;
    pointer-events: none;
  }
  
  .card-p:before {
    content: "";
    position: absolute;
    inset: 0;
    background:
      radial-gradient(circle at calc(var(--x) * 1px) calc(var(--y) * 1px), hsl(0 0% 100% / 0.5), transparent 15vmin),
      transparent;
    background-attachment: fixed;
    pointer-events: none;
    mask:
      linear-gradient(white, #fff9eb) 50% 0 / 100% 2px no-repeat,
      linear-gradient(white, #fff9eb) 50% 100% / 100% 2px no-repeat,
      linear-gradient(white, #fff9eb) 0 50% / 2px 100% no-repeat,
      linear-gradient(white, #fff9eb) 100% 50% / 2px 100% no-repeat;
  }
  .logo-wrap {
    position: absolute; /* Position logo relative to parent */
    height: 30%;
    width: 70%;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center logo */
    transition: top 1s ease, left 1s ease; /* Transition for logo movement */
  }

  .paragraph {
    opacity: 0; /* Initially hide paragraph */
    position: absolute; /* Position paragraph relative to parent */
    top: 100%; /* Start below parent */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center paragraph */
    width: 90%;
    transition: opacity 1s ease, top 1s ease; /* Transition for opacity and position */
  }
  
  .card-p:hover .logo-wrap {
    top: 22%;
    left: 40% /* Move logo up on hover */
  }
  
  .card-p:hover .paragraph {
    opacity: 1; /* Make paragraph visible on hover */
    top: 50%; /* Move paragraph up on hover */
  }
