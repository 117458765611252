.fade-in-text {
    color: black;
    font-size: 16px;
    text-align: center;
    transform: scale(0.94);
    animation: scale 4s forwards cubic-bezier(0.5, 1, 0.89, 1);
    z-index: 2;
    width: 50%;
    margin-top: 20px;
  }
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.fade-in {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
}

@keyframes fade-in {
  0% {
    opacity: 0;
    filter: blur(4px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.visible {
  opacity: 1;
  filter: none;
}

.hidden {
  opacity: 0;
  filter: blur(4px);
  transition: opacity 0.5s, filter 0.5s;
}
@media (max-width: 1200px) {
  .fade-in-text {
    font-size: 15px;
    width: 100%;
  }
}
@media (max-width: 800px) {
  .fade-in-text {
    font-size: 14px;
  }
}
@media (max-width: 420px) {
  .fade-in-text {
    font-size: 13px;
  }
}