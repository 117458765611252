* {
    box-sizing: border-box;
}

h1 {
    color: black;
    text-decoration: none;
}

h2 {
  color: black;
  text-decoration: none;
    width: 40%;
    margin: 0;
}
p {
    font-size: 13px;
}
a {
    text-decoration: none;
}

.logo-slide-up {
  transform: translateY(0); /* Initial position */
  transition: transform 2s ease-in-out; /* Transition effect */
}

.logo-slide-up.slide-up {
  transform: translateY(-10%); /* Slide up by 10% of screen height */
}

.bottom-text {
  opacity: 1; /* Initial opacity */
  transition: opacity 2s ease-in-out; /* Transition effect */
}

.bottom-text.fade-out {
  opacity: 1; /* Fade out */
}

.semis-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-container-wrapper {
  position: relative; /* Add relative positioning to the wrapper */
  display: flex;
  justify-content: center;
}

.card-container {
    display: grid;
    list-style-type: none;
    grid-template-columns: repeat(4, minmax(200px, 1fr)); 
    grid-gap: 2rem 2rem;
    width: 100%;
    overflow: hidden; /* Hide overflow content */
    opacity: 0;
    transition: opacity 2s ease 0.5s;
    margin-top: 250px;
    padding: 0;
    z-index: 10;
    backdrop-filter: blur(10px);
}

.card-container.visible {
  opacity: 1;
}


.card {
    /* background: #8f846e; */
    background: transparent;
    border-radius: 8px;
    border-width: 10;
    width: 300px;
    aspect-ratio: 5 / 6;
    position: relative;
    transition: background 0.1s, color 0.5s ease-in;
    padding: 10px;
    color: black;
    overflow: hidden;
    border: solid 1px #aa9769fd;
    text-align: center;
}
.card svg {
    fill: rgba(12, 12, 12, 0.842);
    transition: fill 0.5s ease-in, stroke 0.5s ease-in;
    height: 80%;
    width: 80%;
}
.card:hover, .hover-mobile {
    color: black;
    --active: 1;
}

.card:hover svg, .hover-mobile svg {
    fill: black;
}

.card:hover .akeanalogo, .hover-mobile .akeanalogo {
    fill: black;
}
.card:hover .akeana-red, .hover-mobile .akeana-red {
    fill: black;
    stroke: red;
}
.card:hover .retymlogo, .hover-mobile .retymlogo {
  fill: black;
}

.card:hover .retym-red, .hover-mobile .retym-red {
  fill: red;
}

.card:hover .alif-logo, .hover-mobile .alif-logo {
  fill: black;
}
.card:hover .alif-blue, .hover-mobile .alif-blue{
  fill: #17a1db;
}

.card:hover .alif-teal, .hover-mobile .alif-teal {
  fill: #5dcbed;
}

.card .lumilenslogo {
  fill: rgba(12, 12, 12, 0.705);
}
.card:hover .lumilenslogo, .hover-mobile .lumilenslogo {
  fill: #227ce5;
}
.card:after {
    content: "";
    position: absolute;
    inset: 0;
    background:
      radial-gradient(circle at calc(var(--x) * 1px) calc(var(--y) * 1px), hsl(0 0% 100% / 0.15), transparent 15vmin);
    background-attachment: fixed;
    opacity: var(--active, 0);
    transition: opacity 0.2s;
    pointer-events: none;
  }
  
  .card:before {
    content: "";
    position: absolute;
    inset: 0;
    background:
      radial-gradient(circle at calc(var(--x) * 1px) calc(var(--y) * 1px), hsl(0 0% 100% / 0.5), transparent 15vmin),
      transparent;
    background-attachment: fixed;
    pointer-events: none;
    mask:
      linear-gradient(white, #fff9eb) 50% 0 / 100% 2px no-repeat,
      linear-gradient(white, #fff9eb) 50% 100% / 100% 2px no-repeat,
      linear-gradient(white, #fff9eb) 0 50% / 2px 100% no-repeat,
      linear-gradient(white, #fff9eb) 100% 50% / 2px 100% no-repeat;
  }
  .logo-wrap {
    position: absolute; /* Position logo relative to parent */
    height: 30%;
    width: 70%;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center logo */
    transition: top 1s ease, left 1s ease; /* Transition for logo movement */
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .paragraph {
    opacity: 0; /* Initially hide paragraph */
    position: absolute; /* Position paragraph relative to parent */
    top: 100%; /* Start below parent */
    left: 50%; /* Center horizontally */
    transform: translateX(-80%); /* Center paragraph */
    width: 80%;
    transition: opacity 1s ease, top 1s ease; /* Transition for opacity and position */
    text-align: left;
    font-size: 14px;
    padding: 15px 10px; 
    border-radius: 4px;
  
  }
  /*
  .paragraph::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #aa976927;
    filter: blur(6px); 
    z-index: -1; 
    margin: -5px; 
  }
  */
  .card:hover .logo-wrap, .hover-mobile .logo-wrap {
    top: 22%;
    left: 35% /* Move logo up on hover */
  }
  
  .card:hover .paragraph, .hover-mobile .paragraph {
    opacity: 1; /* Make paragraph visible on hover */
    top: 50%; /* Move paragraph up on hover */
    left: 50%;
  }

  @media (max-width: 1200px) {
    .card-container {
      grid-template-columns: repeat(2, 1fr); 
      margin-top: 50vw;
    }
  }
  @media (max-width: 800px) {
    .card-container {
      grid-gap: 15px;
      margin-top: 75vw;
    }
    .card {
      width: 45vw;
      aspect-ratio: 5 / 7;
    }
    .paragraph {
      font-size: 12px;
    }
  }
  @media (max-width: 440px) {
    .card-container {
      margin-top: 75vw;
    }
  }